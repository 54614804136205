.footer {
  background: linear-gradient(180deg, #D4851A 0%, rgba(199, 111, 12, 0) 100%), #F3AD1C;
  padding: 80px 0 150px;
  @media (min-width: 768px) {
    height: 357px;
    padding: 150px 0 50px;
  }
}

.menu-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 15px;
  list-style: none;
  flex-wrap: wrap;
  overflow-x: auto;
  max-width: 800px;
  padding: 30px;
  margin: 0 auto 40px;
  background: #FFF;
  font-size: 18px;
  line-height: 1.3;
  border-radius: 30px;
  a {
    color: #731C00;
    text-decoration: none;
    &:hover,
    &:active {
      color: #731C00;
    }
  }
}

.social-menu {
  display: inline-flex;
  align-items: center;
  list-style: none;
  text-align: center;
  li {
    margin: 0 20px;
  }
  a {
    color: #fff;
    transition: all 0.3s linear;
    &:hover,
    &:active {
      color: #fff;
      opacity: 0.5;
    }
  }
}

.discord-btn {
  max-width: 180px;
  bottom: 0;
  right: 7%;
  z-index: 99999;
}
