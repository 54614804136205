// 1. Include Bootstrap
@import "../node_modules/bootstrap/scss/bootstrap";

@import './global/colors';
@import './components/_button';
@import './components/_header';
@import './components/_footer';
@import './components/_timeline';

html {
	overflow-x: hidden;
}

body {
  font-family: 'Lilita One', cursive, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  color: $text-color;
  margin: 0;
  background-color: #FFA825;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
}

img {
  max-width: 100%;
}

@media (min-width: 1600px) {
  .container {
    max-width: 1460px;
  }
}

.color-primary {
  color: #F18F21 !important;
}

.color-accent {
  color: #FCF9DE !important;
}

.section-title {
  font-size: 40px;
  line-height: 1;
  @media (min-width: 1024px) {
    font-size: 80px;
  }
}

.section-hero {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../images/hero.jpg) no-repeat center center;
  background-size: cover;
  min-height: 95vh;
  .hero-pattern-2 {
    position: absolute;
    bottom: -45px;
    left: 10%;
  }
}

.hero-content {
  display: inline-block;
  text-align: center;
  .hero-bottom {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 100px 20px 20px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 43.39%, rgba(0, 0, 0, 0) 100%), #790017;
    border-radius: 20px;
    img {
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .sep-btn {
    display: block;
    width: 2px;
    height: 63px;
    border-left: 2px dashed #3C000B;
  }
}

.section-press {
  padding: 50px 0;
  background: linear-gradient(180deg, #FFBB0B 0%, #FFA825 100%), #FFA825;
  @media (min-width: 1024px) {
    padding: 120px 0;
  }
  .press-inner {
    padding-bottom: 30px;
    border-bottom: 2px dashed rgba(90, 43, 0, 0.3);
    > img {
      margin-bottom: 30px;
      border-radius: 14px;
    }
    span {
      position: relative;
      display: inline-flex;
      align-items: center;
      margin-left: 25px;
      color: #fff;
      &:before {
        content: '';
        display: inline-block;
        margin-right: 10px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: #fff;
      }
    }
  }
}

.divide {
  display: block;
  width: 100%;
  height: 3px;
  margin: 25px 0 40px;
  border: 3px dashed #5A2B00;
}

.swiper {
  padding-bottom: 25px !important;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 5px !important;
}

.swiper-pagination-bullet-active {
  background: #790017 !important;
}

.swiper-button-next,
.swiper-button-prev {
  width: 48px !important;
  height: 70px !important;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain !important;
  margin-top: initial !important;
  transform: translateY(-50%);
  &:after {
    display: none;
  }
}
.swiper-button-next {
  background-image: url(../images/arrow-right.png);
}

.swiper-button-prev {
  background-image: url(../images/arrow-left.png);
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 0 !important;
  @media (min-width: 1024px) {
    right: -100px !important;
  }
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 0 !important;
  @media (min-width: 1024px) {
    left: -100px !important;
  }
}

.section-featured {
  position: relative;
  z-index: 3;
  padding: 30px 0 50px;
}

.featured-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 434px;
  height: 600px;
  max-width: 100%;
  background-image: url(../images/featured-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  text-align: center;
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 290px;
    height: 487px;
    padding: 30px 20px;
    @media (min-width: 768px) {
      padding: 0;
    }
  }
}

.featured-divide {
  display: inline-block;
  width: 166px;
  height: 6px;
  background-image: url(../images/featured-divide.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.section-join-game {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  background: url(../images/section-join-game.jpg) no-repeat center center;
  background-size: cover;
  min-height: 500px;
  margin-top: -150px;
  @media (min-width: 1024px) {
    min-height: 958px;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
  }
  &:before {
    top: 0;
    height: 300px;
    background: linear-gradient(180deg, #FEA927 0%, rgba(254, 169, 39, 0) 100%);
  }
  &:after {
    bottom: 0;
    height: 220px;
    background: linear-gradient(0deg, #978400 15.1%, rgba(180, 158, 7, 0) 100%);
  }
}

.section-nft {
  position: relative;
  padding-top: 50px;
  padding-bottom: 60px;
  background: linear-gradient(3.88deg, rgba(0, 0, 0, 0.2) 4.17%, rgba(0, 0, 0, 0) 97.89%), linear-gradient(0deg, #978400, #978400);
  @media (min-width: 1024px) {
    padding: 110px 50px 120px;
  }
}

.nft-pattern-1,
.nft-pattern-2 {
  position: absolute;
  top: -50px;
  z-index: 99;
}

.nft-pattern-1 {
  left: 10%;
}

.nft-pattern-2 {
  right: 10%;
}

.nft-item {
  width: 100%;
  min-height: 461px;
  background-color: transparent;
  perspective: 1000px;
  &:hover,
  &:active {
    .nft-item__inner {
      transform: rotateY(180deg);
    }
  }
  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.5s;
    transform-style: preserve-3d;
  }
  .nft-front,
  .nft-back {
    position: absolute;
    width: 100%;
    max-width: 100%;
    backface-visibility: hidden;
  }
  .nft-front {
    height: 100%;
    .button {
      position: absolute;
      left: 50%;
      bottom: 25px;
      z-index: 3;
      transform: translateX(-50%);
      min-width: 160px;
    }
  }
  .nft-back {
    min-height: 461px;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #FFDD00;
    transform: rotateY(180deg);
    border-radius: 20px;
    flex-direction: row;
    h2, p {
      border-bottom: 2px dashed #5A2B00;
    }
    h2 {
      font-size: 30px;
      margin-bottom: 30px;
      padding-bottom: 20px;
    }
    p {
      flex: 1;
      font-size: 20px;
      margin-bottom: 25px;
    }
    a {
      cursor: pointer;
    }
  }
  .nft-frame {
    border-radius: 20px;
  }
  .nft-img {
    position: absolute;
    z-index: 2;
    min-width: 200px;
    max-width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -62%);

    @media (min-width: 2000px) {
      max-width: 60%;
    }
  }
}

.section-tokenomics {
  position: relative;
  padding: 60px 0;
  z-index: 3;
  display: flex;
  background: url(../images/tokenomics-bg.jpg) no-repeat center center;
  background-size: cover;
  min-height: 1080px;
  @media (min-width: 1024px) {
    padding: 110px 0;
  }
}

.divide-2 {
  display: block;
  width: 100%;
  height: 3px;
  margin: 25px 0 40px;
  background: url(../images/divide-2.png) no-repeat center center;
}

.tokenomic-img-pattern {
  position: absolute;
  z-index: 3;
  bottom: -70px;
  right: 0;
  max-width: 300px;
  @media (min-width: 1280px) {
    max-width: none;
    right: -47%;
  }
}

.tokenomic-img-pattern-2 {
  right: 0;
  bottom: -80px;
  max-width: 200px;
  @media (min-width: 1600px) {
    max-width: none;
  }
}

.section-roadmap {
  position: relative;
  z-index: 1;
  padding: 120px 0 0;
  background: #D6C422;
}

.roadmap-wrapper {
  margin-bottom: 50px;
  @media (min-width: 1024px) {
    margin-bottom: 100px;
  }
  @media (min-width: 1200px) {
    margin-bottom: 150px;
  }
  @media (min-width: 1400px) {
    margin-bottom: 180px;
  }
}

.roadmap-item {
  overflow: hidden;
  &__inner {
    text-align: center;
  }
  &--first {
    .roadmap-divide .roadmap-line:first-child {
      background: none;
    }
  }
  &--last {
    .roadmap-divide .roadmap-line:last-child {
      background: none;
    }
  }
  h2 {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 218px;
    height: 80px;
    background: url(../images/phase-bg.png) no-repeat center center;
    background-size: cover;
    color: #fff;
    font-size: 20px;
    line-height: 26px;
  }
  .roadmap-content {
    position: relative;
    margin: 0 auto;
    max-width: 218px;
    padding: 20px;
    background: #936A00;
    border-radius: 20px;
    border: 5px solid #FFE600;
    &:before {
      content: '';
      position: absolute;
      top: -19px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 0;
      height: 0;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-bottom: 14px solid #FFE600;
    }
  }
  .roadmap-divide {
    margin: 20px 0 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 4;
    .roadmap-dot {
      position: relative;
      z-index: 4;
      display: block;
      min-width: 30px;
      width: 30px;
      height: 30px;
      background: url(../images/roadmap-dot.png) no-repeat center center;
      background-size: cover;
    }
    .roadmap-line {
      position: relative;
      z-index: 3;
      display: block;
      width: 270px;
      height: 1px;
      background: #5A2B00;
    }
  }
  ul {
    padding-left: 1rem;
    font-size: 14px;
    line-height: 1.3;
    text-align: left;
    color: #fff;
  }
}

.game-img-with-cloud {
  position: relative;
}
.cloud-1,
.cloud-2 {
  position: absolute;
  left: 0;
  z-index: 1;
}
.cloud-1 {
  top: 0;
  transform: translateY(-50%);
}
.cloud-2 {
  bottom: 0;
  transform: translateY(20%);
}

.section-advisor {
  position: relative;
  padding-top: 120px;
  padding-bottom: 80px;
  background: #FFFBDD;
  z-index: 3;
}

.advisor-item {
  position: relative;
  margin-bottom: 40px;
  padding-bottom: 90px;
  img {
    width: 100%;
    border-radius: 20px;
  }
  .advisor-content {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 270px;
    max-width: 100%;
    padding: 20px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 43.39%, rgba(0, 0, 0, 0) 100%), #790017;
    color: #fff;
    border-radius: 20px;
    h2 {
      font-size: 26px;
      line-height: 1.3;
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
    }
  }
  .advisor-divide {
    display: block;
    width: 100%;
    height: 2px;
    margin: 20px 0;
    opacity: 0.5;
    border: 2px dashed #8E4400;
  }
}

.advisor-pattern {
  position: absolute;
  bottom: 0;
  right: 15px;
  z-index: 999;
  max-width: 160px;
  @media (min-width: 768px) {
    max-width: 240px;
  }
  @media (min-width: 1024px) {
    max-width: 360px;
  }
  @media (min-width: 1600px) {
    max-width: 490px;
  }
}

.section-partner {
  position: relative;
  padding-bottom: 435px;
  background: linear-gradient(180deg, rgba(0, 130, 171, 0) 0%, rgba(0, 130, 171, 0.4) 100%), #FFFBDD;
  background-image: url(../images/partner-bg.jpg);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}

.partner-inner {
  margin: 0 auto;
  padding: 100px 60px;
  max-width: 1460px;
  min-height: 1022px;
  @media (min-width: 1280px) {
    padding: 100px 120px;
    background: url(../images/partner-bg.png) no-repeat center center;
  }
  background-size: contain;
  .partner-divide {
    display: block;
    margin: 20px 0;
    width: 100%;
    height: 2px;
    border: 2px dashed #5A2B00;
    opacity: 0.3;
  }
}

.footer-pattern-1,
.footer-pattern-2 {
  max-width: 150px;
  @media (min-width: 1024px) {
    max-width: 200px;
  }
  @media (min-width: 1600px) {
    max-width: 400px;
  }
  @media (min-width: 1900px) {
    max-width: none;
  }
}

.footer-pattern-1 {
  bottom: 0;
  left: 0;
}

.footer-pattern-2 {
  bottom: 0;
  right: 0;
}

.footer-pattern-3 {
  top: -30%;
  left: 50%;
  transform: translateX(-50%);
}

.section-guild {
  padding: 130px 0;
  background: url(../images/guild-bg.jpg) no-repeat center bottom;
  background-size: cover;
}

.guild-content {
  display: block;
  width: 434px;
  height: 480px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  background: url(../images/featured-bg.png) no-repeat center top;
  background-size: contain;
  font-size: 20px;
  line-height: 1.3;
  .guild-item {
    padding: 60px 50px;
  }
  @media (min-width: 768px) {
    height: 600px;
  }
  @media (min-width: 1400px) {
    font-size: 24px;
  }
}


