.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px 14px 20px;
  background: radial-gradient(100% 274.18% at 97.31% 0%, rgba(255, 230, 0, 0.4) 0%, rgba(255, 230, 0, 0) 100%), linear-gradient(180deg, rgba(255, 229, 63, 0.2) 0%, rgba(255, 229, 63, 0) 100%), #FF931D;
  color: #731C00;
  font-size: 23px;
  line-height: 1.3;
  border-radius: 14px;
  text-decoration: none;
  transition: all 0.3s linear;
  &:hover {
    transform: translateY(-6px);
    color: #731C00;
    box-shadow: 0 6px 0 #471712, inset 0 4px 0 rgb(255 220 95 / 70%);
  }
}
