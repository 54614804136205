.header-nav {
  @media (max-width: 991.9px) {
    position: absolute !important;
    width: 100%;
    z-index: 999;
  }
  .container-xl {
    @media (min-width: 992px) {
      height: 90px;
      background: url(../images/header-bg.png) no-repeat center center;
      background-size: contain;
    }
  }
  .navbar-nav {
    align-items: center;
  }
  .navbar-brand {
    margin: 0 auto;
  }
  .navbar-brand.d-lg-block {
    transform: translateY(20px);
  }
  .header-btn {
    display: inline-flex;
    padding: 10px 14px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 43.39%, rgba(0, 0, 0, 0) 100%), #790017;
    color: #FF931D;
    font-size: 18px;
    line-height: 1.3;
    border-radius: 14px;
    text-decoration: none;
  }
}

.logo-mobile {
  max-width: 90px;
}

.navbar-expand-lg .navbar-nav {
  &:first-child {
    margin-left: 3rem;
    @media (min-width: 1400px) {
      margin-left: 5rem;
    }
  }
  &:last-child {
    margin-right: 3rem;
    @media (min-width: 1400px) {
      margin-right: 5rem;
    }
  }
  .nav-link {
    padding: 1rem;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: #731C00;
  font-size: 18px;
  &:hover,
  &:active {
    color: #fff;
  }
}

.navbar-light .offcanvas-body .navbar-nav .nav-link {
  &:hover,
  &:active {
    color: #731C00;
    text-decoration: underline;
  }
}
